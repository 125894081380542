import styled from 'styled-components';
import { breakpoint } from 'theme/breakpoints';

import Container from 'components/atoms/container';
import Typography from 'components/atoms/typography';

export const StyledWrapper = styled.div`
  background: ${({ theme }) => theme.colors.grayscaleGray0};

  ${breakpoint.md} {
    padding-bottom: 56px;
  }
`;

export const WrapperFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.gap.sm};
  padding-block: ${({ theme }) => theme.gap.md};

  ${breakpoint.md} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  ${breakpoint.md} {
    flex-wrap: wrap;
  }

  ${breakpoint.lg} {
    flex-wrap: nowrap;
  }
`;

export const WrapperFilters = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.gap.xs};

  ${breakpoint.md} {
    flex-direction: row;
    gap: 0;
  }
`;

export const FilterOption = styled.button`
  padding: 10px 24px;
  background: ${({ theme }) => theme.colors.grayscaleGray0};
  border: 1px solid ${({ theme }) => theme.colors.gray10};

  &.active,
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.white};
  }

  ${breakpoint.md} {
    &.filter0,
    &.filter1 {
      border-right: 0;
    }
  }
`;

export const StyledContainer = styled(Container)`
  max-width: ${({ theme }) => theme.wrapper};
`;

export const StyledDepartmentHeading = styled(Typography)`
  ${breakpoint.lg} {
    max-width: 460px;
  }
`;
