import React, { useState } from 'react';

import DepartmentItem from 'components/molecules/department-item';

import {
  FilterOption,
  StyledContainer,
  StyledDepartmentHeading,
  StyledWrapper,
  WrapperFilters,
  WrapperFiltersContainer,
} from './departments-list-cards.styles';

export type FiltersType = {
  template: {
    departmentsData: {
      departmentList: {
        heading: string;
        filterOptions: {
          category: string[];
          option: string;
        }[];
      };
    };
  };
};

const DepartmentsListCards = ({ departmentsFilters, departmentsList }) => {
  const [activeFilter, setActiveFilter] = useState<string[]>(
    departmentsFilters.nodes[0].template.departmentsData.departmentList.filterOptions[0].category
  );

  const filteredList = departmentsList.nodes.filter((card) =>
    activeFilter.some((element) =>
      card?.template?.departmentDetailsData?.contactDetails?.category?.includes(element)
    )
  );

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return (
    <>
      {departmentsFilters.nodes || filteredList ? (
        <StyledWrapper>
          <StyledContainer>
            {departmentsFilters.nodes
              ? departmentsFilters.nodes.map((value) => (
                  <WrapperFiltersContainer>
                    <StyledDepartmentHeading color="gray100" variant="title3" component="h3">
                      {value.template.departmentsData.departmentList.heading}
                    </StyledDepartmentHeading>
                    <WrapperFilters>
                      {value.template.departmentsData.departmentList.filterOptions.map(
                        (filter, index) => (
                          <li key={filter.option}>
                            <FilterOption
                              onClick={() => setActiveFilter(filter.category)}
                              className={`filter${index} ${
                                activeFilter === filter.category ? 'active' : ''
                              }`}
                            >
                              {filter.option}
                            </FilterOption>
                          </li>
                        )
                      )}
                    </WrapperFilters>
                  </WrapperFiltersContainer>
                ))
              : null}
          </StyledContainer>
          {filteredList
            ? filteredList.map((value) => (
                <DepartmentItem uri={value.uri} {...value.template.departmentDetailsData} />
              ))
            : null}
        </StyledWrapper>
      ) : null}
    </>
  );
};

export default DepartmentsListCards;
