import styled, { css } from 'styled-components';
import { breakpoint } from 'theme';

import { Button } from 'components/atoms/button';
import EmailOpen from 'components/atoms/icon/icons/email-open';
import Linkedin from 'components/atoms/icon/icons/linkedin';
import Link from 'components/atoms/link';
import Typography from 'components/atoms/typography';
// Mixin
const sizeIcons = css`
  width: 40px;
  height: 40px;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray40};
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.grayscaleGray0};
`;

export const ItemWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  margin: auto auto ${({ theme }) => theme.gap.sm};

  ${breakpoint.md} {
    display: flex;
    max-width: ${({ theme }) => theme.wrapper};
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 192px;
  position: relative;

  ${breakpoint.md} {
    max-width: 284px;
    height: 340px;
  }
`;

export const DescriptionWrapper = styled.div`
  padding: ${({ theme }) => theme.gap.sm};
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray0};

  ${breakpoint.md} {
    max-width: 463px;
    padding: 24px;
    border-bottom: unset;
    border-right: 1px solid ${({ theme }) => theme.colors.gray0};
  }
`;
export const WrapperCategory = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.gap.xxs};
`;

export const SocialDescriptionWrapper = styled.div`
  padding: ${({ theme }) => theme.gap.sm} ${({ theme }) => theme.gap.sm} 24px;
  width: 100%;

  ${breakpoint.md} {
    padding: 24px;
    max-width: 360px;
  }
`;

export const WrapperIcons = styled.div`
  margin-top: ${({ theme }) => theme.gap.xs};
  display: flex;
  gap: ${({ theme }) => theme.gap.xs};
`;

export const EmailStyled = styled(EmailOpen)`
  ${sizeIcons}
`;

export const LinkedinStyled = styled(Linkedin)`
  ${sizeIcons}
`;

export const SubTitle = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.gap.xs};
  font-weight: 600 !important;

  ${breakpoint.md} {
    margin-bottom: ${({ theme }) => theme.gap.sm};
  }
`;

export const NameOfManager = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.gap.xs};
`;

export const LinkStyle = styled(Link)`
  color: ${({ theme }) => theme.colors.complementary2};
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
  width: 100%;
  height: 16px;

  span {
    border-bottom: 1px solid ${({ theme }) => theme.colors.complementary2};
    position: revert;

    &:hover {
      border-bottom: unset;
    }
  }

  ${breakpoint.md} {
    font-size: 16px;
  }
`;

export const ButtonStyled = styled(Button)`
  ${breakpoint.md} {
    margin-top: 24px;
  }
`;

export const StyledAddressWrapper = styled.div`
  margin-bottom: 24px;
`;

export const ContactDataWrapper = styled(StyledAddressWrapper)``;

export const ItemContactWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.gap.xs};
  align-items: center;
  margin-bottom: ${({ theme }) => theme.gap.xs};
`;

export const WrapperTextBanner = styled.div`
  position: absolute;
  width: 100%;
  bottom: 24px;
  left: 24px;
`;

export const WrapperAction = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 18px;
  justify-content: start;

  ${breakpoint.md} {
    align-items: start;
    flex-direction: column;
    gap: 0;
  }
`;
