import React from 'react';

const EmailOpen = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    role="img"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 4.3L14 12C14 12.5128 13.614 12.9355 13.1166 12.9933L13 13H3.00001C2.48718 13 2.06451 12.614 2.00674 12.1166L2.00001 12L2 4.3L7.34151 8.75259C7.71853 9.08249 8.28149 9.08249 8.65852 8.75259L14 4.3ZM12.433 3L8.00001 6.67125L3.566 3H12.433Z"
      fill="currentColor"
    />
  </svg>
);

export default EmailOpen;
