import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { PageContextType } from 'types';

import Layout from 'components/templates/layout';
import DepartmentsListCards, { FiltersType } from 'components/organisms/departments-list-cards';
import HeadTitleDescription from 'components/organisms/head-title-description';
import { DepartmentItemType } from 'components/molecules/department-item';
import Breadcrumb from 'components/atoms/breadcrumb';

type DataProps = {
  departmentsPage: {
    departmentsData: {
      banner: {
        heading: string;
        text: string;
      };
    };
  };
  departmentsFilters: {
    nodes: FiltersType[];
  }[];
  departmentsList: {
    nodes: {
      uri: string;
      template: {
        departmentDetailsData: DepartmentItemType;
      };
    }[];
  };
};
const DepartmentsPage = ({
  pageContext,
  data: {
    departmentsPage: {
      departmentsData: {
        banner: { text, heading },
      },
    },
    departmentsFilters,
    departmentsList,
  },
}: PageProps<DataProps, PageContextType>) => (
  <Layout {...{ pageContext }}>
    <HeadTitleDescription showDots={false} titleTop={heading} subtitleTop={text}>
      <Breadcrumb {...{ pageContext }} />
    </HeadTitleDescription>
    <DepartmentsListCards
      departmentsFilters={departmentsFilters}
      departmentsList={departmentsList}
    />
  </Layout>
);
export const query = graphql`
  query DepartmentsPage($id: String, $langCode: String) {
    departmentsPage: wpPage(id: { eq: $id }) {
      departmentsData {
        banner {
          heading
          text
        }
      }
    }
    departmentsFilters: allWpPage(
      filter: {
        template: { templateName: { eq: "Department" } }
        language: { slug: { eq: $langCode } }
      }
    ) {
      nodes {
        ... on WpPage {
          template {
            ... on WpTemplate_Department {
              departmentsData {
                departmentList {
                  heading
                  filterOptions {
                    category
                    option
                  }
                }
              }
            }
          }
        }
      }
    }
    departmentsList: allWpPage(
      filter: {
        template: { templateName: { eq: "DepartmentDetails" } }
        language: { slug: { eq: $langCode } }
      }
    ) {
      nodes {
        uri
        ... on WpPage {
          template {
            ... on WpTemplate_DepartmentDetails {
              departmentDetailsData {
                additionalCardElements {
                  cardImage {
                    localFile {
                      extension
                      url
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
                departmentBanner {
                  heading
                  subHeading
                }
                managerSection {
                  businessCards {
                    businessCard {
                      contactList {
                        type
                        link {
                          internal: wpInternal {
                            ... on WpPage {
                              uri
                            }
                          }
                          external {
                            target
                            url
                          }
                          ariaLabel
                        }
                      }
                      fullName
                      position
                      positionTitle
                    }
                  }
                }
                contactDetails {
                  address
                  category
                  linkButton {
                    label

                    link {
                      ariaLabel
                      external {
                        target
                        title
                        url
                      }
                      internal: wpInternal {
                        ... on WpPage {
                          uri
                        }
                      }
                    }
                  }
                  contactListAddress: contactList {
                    fieldGroupName
                    text
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default DepartmentsPage;
