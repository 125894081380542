import React from 'react';

import { ButtonType } from 'components/atoms/button';
import Container from 'components/atoms/container';
import Email from 'components/atoms/icon/icons/email';
import Phone from 'components/atoms/icon/icons/phone';
import Link, { LinkUrlType } from 'components/atoms/link';
import Typography from 'components/atoms/typography';
import WpImage, { WpImageType } from 'components/atoms/wp-image';

import useCurrentLanguage from 'hooks/use-current-language';

import {
  ButtonStyled,
  ContactDataWrapper,
  DescriptionWrapper,
  EmailStyled,
  ImageWrapper,
  ItemContactWrapper,
  ItemWrapper,
  LinkedinStyled,
  LinkStyle,
  NameOfManager,
  SocialDescriptionWrapper,
  StyledAddressWrapper,
  SubTitle,
  Wrapper,
  WrapperAction,
  WrapperCategory,
  WrapperIcons,
  WrapperTextBanner,
} from './department-item.styles';

export type DepartmentItemType = {
  uri: string;
  managerSection: {
    businessCards: {
      businessCard: {
        fullName: string;
        positionTitle: string;
        position: string;
        contactList: {
          type: string;
          link: LinkUrlType;
        }[];
      };
    }[];
  };
  contactDetails: {
    address: string;
    category: string[];
    linkButton: ButtonType;
    contactListAddress: {
      fieldGroupName: string;
      text: string;
      type: string;
    }[];
  };
  additionalCardElements: {
    cardImage: WpImageType;
  };
  departmentBanner: {
    heading: string;
    subHeading: string;
  };
};

const DepartmentItem = ({
  uri,
  contactDetails: { contactListAddress, category, address, linkButton },
  managerSection: { businessCards },
  additionalCardElements: { cardImage },
  departmentBanner: { heading, subHeading },
}: DepartmentItemType) => {
  const { contactList, fullName, position, positionTitle } = businessCards[0].businessCard;
  const currentLanguage = useCurrentLanguage();
  const textMoreAbout =
    currentLanguage === 'PL' ? 'Więcej o oddziale' : 'More about the department';

  return (
    <Wrapper>
      <Container>
        <ItemWrapper>
          <ImageWrapper>
            <WpImage {...cardImage} />
            <WrapperTextBanner>
              <Typography color="white" variant="title2" component="h2">
                {heading}
              </Typography>
              <Typography color="whiteAlpha72" variant="title4">
                {subHeading}
              </Typography>
            </WrapperTextBanner>
          </ImageWrapper>
          <DescriptionWrapper>
            <WrapperCategory>
              {category.map((cat) => (
                <SubTitle variant="body3" color="gray60" html={cat} />
              ))}
            </WrapperCategory>
            <StyledAddressWrapper>
              <Typography color="gray80" variant="title4" component="h3" html={address} />
            </StyledAddressWrapper>
            <ContactDataWrapper>
              {contactListAddress
                ? contactListAddress.map((contact) => (
                    <ItemContactWrapper>
                      {contact.type === 'phone' ? <Phone /> : <Email />}
                      <Typography color="gray80" variant="title4">
                        {contact.text}
                      </Typography>
                    </ItemContactWrapper>
                  ))
                : null}
            </ContactDataWrapper>
            <WrapperAction>
              <LinkStyle
                internal={{ uri }}
                visuallyHiddenText={textMoreAbout}
                ariaLabel={textMoreAbout}
              />
              <ButtonStyled type="submit" {...linkButton} />
            </WrapperAction>
          </DescriptionWrapper>
          <SocialDescriptionWrapper>
            <SubTitle variant="body3" color="gray60">
              {position}
            </SubTitle>
            <NameOfManager color="gray100" variant="title6">
              {fullName}
            </NameOfManager>
            <SubTitle variant="body3" color="gray60">
              {positionTitle}
            </SubTitle>
            <WrapperIcons>
              {contactList?.map(({ link, type }) => (
                <div>
                  {type === 'email' ? (
                    <Link {...link}>
                      <EmailStyled />
                    </Link>
                  ) : (
                    <Link {...link}>
                      <LinkedinStyled />
                    </Link>
                  )}
                </div>
              ))}
            </WrapperIcons>
          </SocialDescriptionWrapper>
        </ItemWrapper>
      </Container>
    </Wrapper>
  );
};

export default DepartmentItem;
