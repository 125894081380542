import React from 'react';

const Linkedin = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    role="img"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6634 0C15.4016 0 16 0.598017 16 1.33663V14.6634C16 15.4016 15.402 16 14.6634 16H1.33663C0.59843 16 0 15.402 0 14.6634V1.33663C0 0.59843 0.598017 0 1.33663 0H14.6634ZM10.815 6C9.68781 6 8.91395 6.49453 8.56426 7.07216L8.5 7.18867V6.1H6.33333V13.7667H8.66667V10.053C8.66667 8.80833 9.125 8.01667 10.2267 8.01667C10.9792 8.01667 11.2991 8.67574 11.3307 9.85216L11.3333 10.053V13.7667H13.6667V9.53233C13.6667 7.246 13.1197 6 10.815 6ZM4.66667 6H2.33333V13.6667H4.66667V6ZM3.5 2C2.67167 2 2 2.67167 2 3.5C2 4.32833 2.67167 5 3.5 5C4.32833 5 5 4.32833 5 3.5C5 2.67167 4.32833 2 3.5 2Z"
      fill="currentColor"
    />
  </svg>
);

export default Linkedin;
